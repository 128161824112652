/* lib imports */
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';
import VisualCrosswordGrid from '../components/VisualCrosswordGrid';
import { useNavigate } from 'react-router-dom';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* import helpers */
import { formatTime } from '../pages/Play';

/* style */
import '../style/recentGames.css';

function RecentGames() {
  const { user } = useContext(AuthContext);
  const [recentGames, setRecentGames] = useState([]);
  const [loading, setLoading] = useState(true);

  /* create history object for navigation */
  const navigate = useNavigate(); 

  /* mobile handling */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
  }, []);

  /* fetch recent games */
  const fetchRecentGamesAndPercentiles = async () => {
    try {
        const response = await fetch(`${apiUrl}/user/getRecentPuzzles`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        });
        const data = await response.json();
        const gamesWithPercentiles = await Promise.all(data.map(async (game) => {
            const res = await fetch(`${apiUrl}/leaderboard/calculate-percentile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ puzzleId: game.puzzleId, completionTime: game.completionTime })
            });
            if (!res.ok) {
                console.error('Failed to fetch percentile', await res.text());
                return { ...game, percentile: undefined };
            }
            const percentileData = await res.json();
            return { ...game, percentile: percentileData.percentile, skillRatingAdjustment: game.skillRatingAdjustment };
          }));
        setRecentGames(gamesWithPercentiles);
        setLoading(false);
      } catch (error) {
          console.error('Error fetching recent games and percentiles:', error);
          setLoading(false);
      }
  };

  /* fetch recent games with percentile included */
  useEffect(() => {
    if (user) {
      fetchRecentGamesAndPercentiles();
    }
  }, [user]);

  /* helper to format our time */
  const formatDateTime = (dateTime) => {
    const dateOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };
    const timeOptions = { hour: 'numeric', minute: '2-digit', hour12: true };
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString('en-US', dateOptions).replace(/\//g, '/');
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions).toLowerCase();
    return `${formattedDate} - ${formattedTime}`;
  };

  /* returns the leftover milliseconds of a leaderboard time */
  function getMilliseconds(time) {
    return time % 1000;
  }

  /* visual */
  if (isMobile) {
    return (
      <div className="mobile-recent-games-container">
  
        <div className='mobile-rg-header'>
          <button className='mobile-rg-back-button' onClick={() => navigate('/')}>
            <i className='bx bxs-left-arrow'></i>
          </button>         

          <h1>recent games</h1>

          <button className='mobile-rg-button' onClick={() => navigate('/play/random')}>
            <i className='bx bxs-dice-6'></i>
          </button>
          
        </div>
  
        {loading ? (
          <div className="loading-container" style={{ flex: 1 }}>
            <div className="loading-message">
              loading recent games...
            </div>
          </div>           
        ) : (
          recentGames.length > 0 ? (
            <ul className="mobile-recent-games-list">
              {recentGames.map(game => (
                <li key={game._id}>
                  <div className='recent-game-inner-container'>
                    <VisualCrosswordGrid grid={game.puzzleId.grid} />
  
                    <div className='recent-game-attributes'>
                      <div className='rg-attribute'>
                        <div className='rg-attribute-header'>time</div> 
                        <div className="time">
                          <span className="rg-timer">{formatTime(game.completionTime)}</span>
                          <span className="milliseconds">{getMilliseconds(game.completionTime).toString().padStart(3, '0')}</span>
                        </div>                    
                      </div>
  
                      <div className='rg-attribute'>
                          <div className='rg-attribute-header'>percentile</div>
                          <div className='rg-timer'>{game.percentile?.toFixed(2) ?? '--'}%</div>
                      </div>
  
                      <div className='rg-attribute'>
                        <div className='rg-attribute-header'>sr change</div> 
                        <div className='rg-timer'>
                          { game.skillRatingAdjustment === -1 || game.skillRatingAdjustment === -2 ? "+0" :
                            (game.skillRatingAdjustment > 0 ? `+${game.skillRatingAdjustment}` : game.skillRatingAdjustment)
                          }                     
                        </div>                    
                      </div>
  
                      {game.usedHelp && (
                        <div className='rg-attribute'>
                          <button className='rg-icon'>
                            <i className='bx bxs-donate-heart'></i>
                          </button>                           
                        </div>
                      )}
  
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="no-recent-games">no recent games found.</div>
          )
        )}
      </div>
    );
  } else {
    return (
      <div className="recent-games-container">
  
        <div className='back-recent-games-header'>
          <button className='back-button' onClick={() => navigate('/')}>
            <i className='bx bxs-left-arrow'></i>
          </button>           
          <h1>recent games</h1>
        </div>
  
        {loading ? (
          <div className="loading-container" style={{ flex: 1 }}>
            <div className="loading-message">
              loading recent games...
            </div>
          </div>           
        ) : (
          recentGames.length > 0 ? (
            <ul className="recent-games-list">
              {recentGames.map(game => (
                <li key={game._id}>
                  <strong> {formatDateTime(game.playedAt)} </strong> 
                  <div className='recent-game-inner-container'>
                    <VisualCrosswordGrid grid={game.puzzleId.grid} />
  
                    <div className='recent-game-attributes'>
                      <div className='rg-attribute'>
                        <div className='rg-attribute-header'>time</div> 
                        <div className="time">
                          <span className="rg-timer">{formatTime(game.completionTime)}</span>
                          <span className="milliseconds">{getMilliseconds(game.completionTime).toString().padStart(3, '0')}</span>
                        </div>                    
                      </div>
  
                      <div className='rg-attribute'>
                          <div className='rg-attribute-header'>percentile</div>
                          <div className='rg-timer'>{game.percentile?.toFixed(2) ?? '--'}%</div>
                      </div>
  
                      <div className='rg-attribute'>
                        <div className='rg-attribute-header'>sr change</div> 
                        <div className='rg-timer'>
                          { game.skillRatingAdjustment === -1 || game.skillRatingAdjustment === -2 ? "+0" :
                            (game.skillRatingAdjustment > 0 ? `+${game.skillRatingAdjustment}` : game.skillRatingAdjustment)
                          }                     
                        </div>                    
                      </div>
  
                      {game.usedHelp && (
                        <div className='rg-attribute'>
                          <button className='rg-icon'>
                            <i className='bx bxs-donate-heart'></i>
                          </button>                           
                        </div>
                      )}
  
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="no-recent-games">no recent games found.</div>
          )
        )}
      </div>
    );
  }
}

export default RecentGames;
