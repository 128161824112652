/* lib imports */
import React, { useContext, useEffect, useState, useRef } from 'react';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* style */
import '../style/multiplayer.css';

/* leaderboard */
function Multiplayer() {

    /* get user */
    const { user } = useContext(AuthContext);
    const username = user.username

    /* create history object for navigation */
    const navigate = useNavigate(); 

    /* mobile handling */
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    /* fetch queue count on mount and every 15 seconds */
    useEffect(() => {
        fetchQueueCount();
        const interval = setInterval(fetchQueueCount, 15000);
        return () => clearInterval(interval);
    }, []);

    /* state and refs */
    const [isSearching, setIsSearching] = useState(false);
    const [queueMessage, setQueueMessage] = useState("");
    const [numPlayers, setNumPlayers] = useState(0);
    const pollIntervalRef = useRef(null);
    const searchStartTimeRef = useRef(null);

    /* the many waits */
    const waitingMessages = [
        "Waiting.",
        "Waiting..",
        "Waiting...",
        "Waiting....",
        "Waiting....."
    ];
    const waitingIndexRef = useRef(0);

    /* fetch number of players in queue */
    const fetchQueueCount = async () => {
        try {
            const response = await fetch(`${apiUrl}/multiplayer/queue/count`, 
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            });
            const data = await response.json();
            if (response.ok && data.count !== undefined) {
                setNumPlayers(data.count);
            } else {
                console.error("Error fetching queue count:", data);
            }
        } catch (error) {
            console.error("An error occurred while fetching queue count:", error);
        }
    };

    /* handle leaving queue */
    const handleLeaveQueue = async () => {
        if (!username) {
          console.error("No username set!");
          return;
        }
        try {
          const response = await fetch(`${apiUrl}/multiplayer/queue/leave`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
             },
            body: JSON.stringify({ username })
          });
    
          const data = await response.json();
    
          if (response.ok) {
            setQueueMessage("left queue :("); 
          } else {
            setQueueMessage("error leaving queue");
          }
        } catch (error) {
          console.error("An error occurred while leaving queue:", error);
          setQueueMessage("error leaving queue");
        }
    };

    /* handle joining queue */
    const handleJoinQueue = async () => {
        /* essential */
        if (!username) {
            console.error("No username set!");
            return;
        }

        /* hit join queue endpoint */
        try {
            const response = await fetch(`${apiUrl}/multiplayer/queue/join`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                 },                
                 body: JSON.stringify({ username })
            });
            const data = await response.json();

            /* healthy */
            if (response.ok) {
                setQueueMessage("joined queue!");
                setTimeout(() => {
                    if (isSearching) {
                        setQueueMessage("waiting");
                    }
                }, 2500); /* switches to waiting after 2.5s */
            /* error */
            } else {
                setQueueMessage("error joining queue");
            }

        /* worse error */ 
        } catch (error) {
            console.error("An error occurred while joining queue:", error);
            setQueueMessage("error joining queue");
        }
    };
  
    /* Check if you are matched 
        - Called every few seconds once we are the "waiter"
        - If matched, stop polling and navigate to the match
        - If 1 minute passes, leave queue (time out)
    */
    const checkIfMatched = async () => {
        const maxWaitMs = 60_000;
        const elapsed = Date.now() - (searchStartTimeRef.current || 0);
    
        /* timeout */
        if (elapsed > maxWaitMs) {
          console.log("Search timed out, leaving queue...");
          await handleLeaveQueue();
          fetchQueueCount();
          stopPolling();
          setIsSearching(false);
          setQueueMessage("No other players found.");
          return;
        }

        /* check status */
        try {
            const response = await fetch(`${apiUrl}/multiplayer/queue/status?username=${username}`, 
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
            });
            const data = await response.json();

            /* ensure no self games */
            console.log("Data:", data)
            if (data.player1 === username && data.player2 === username) {
                console.log("<SN> Still waiting...");
                waitingIndexRef.current += 1;
                const nextMsg = waitingMessages[waitingIndexRef.current % waitingMessages.length];
                setQueueMessage(nextMsg);
            }
            /* match found */
            else if (data.status === 'matched') {
                console.log("You got matched while waiting:", data);
                setQueueMessage(`Matched! Match ID: ${data.matchUuid || 'N/A'}`);
        
                stopPolling();
                setIsSearching(false);
                await handleLeaveQueue()

                /* play the game */
                navigate(`/play/${data.puzzleId}?matchId=${data.matchUuid}`);
            
            /* still waiting */
            } else if (data.status === 'waiting') {
                console.log("Still waiting...");
                waitingIndexRef.current += 1;
                const nextMsg = waitingMessages[waitingIndexRef.current % waitingMessages.length];
                setQueueMessage(nextMsg);
            /* bad error */ 
            } else {
                console.error("Unexpected status from server:", data);
            }

        /* worse error */
        } catch (error) {
            console.error("Error while polling match status:", error);
        }
    };
    
    /* end polling */
    const stopPolling = () => {
        if (pollIntervalRef.current) {
            clearInterval(pollIntervalRef.current);
            pollIntervalRef.current = null;
        }
    };

    /* initiate searching for game logic */
    const handleSearchGame = async () => {

        /* cancel search */
        if (isSearching) {
            setIsSearching(false)
            stopPolling()
            await handleLeaveQueue()
            fetchQueueCount()
            return;
        }; 

        /* setup */
        setIsSearching(true);
        searchStartTimeRef.current = Date.now();    
        console.log("Searching for a game...");

        /* check for anyone in queue */
        try {
            const findResponse = await fetch(`${apiUrl}/multiplayer/queue/find-waiter`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            });            
            const findData = await findResponse.json();

            /* we found a user */
            if (findData.waitingUser) {
                console.log('Found a user in the queue:', findData.waitingUser);

                /* confirm not us */
                if (findData.waitingUser === username) {
                    await handleLeaveQueue()
                    stopPolling()
                    setQueueMessage("Found ourself :( try again")
                    setIsSearching(false)
                    return
                }
                
                /* fetch a random puzzle and get its id */
                const puzzleRes = await fetch(`${rootUrl}/puzzles`);
                if (!puzzleRes.ok) {
                    throw new Error('Failed to fetch a random puzzle');
                }
                const puzzleData = await puzzleRes.json(); 
                const randomPuzzleId = puzzleData._id;
        
                /* create a match with the other player */
                const createMatchResponse = await fetch(`${apiUrl}/multiplayer/match/create`, {
                    method: 'POST',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                     },                         
                    body: JSON.stringify({
                        puzzleId: randomPuzzleId,
                        player1: findData.waitingUser,
                        player2: username,  // your current user
                    }),
                });
        
                if (!createMatchResponse.ok) {
                    throw new Error('Failed to create match');
                }
        
                const matchData = await createMatchResponse.json();
                console.log('Match created:', matchData);
                setQueueMessage(`Matched! Match ID: ${matchData.matchUuid}`);
                setIsSearching(false)

                /* play the match */
                navigate(`/play/${matchData.puzzleId}?matchId=${matchData.matchUuid}`);
                return
            
            /* no other user */
            } else {
                /* add ourselves to the queue */
                await handleJoinQueue();

                /* get accurate queue */
                fetchQueueCount()

                /* begin polling for a minute or until found match (will also put you in match) */
                pollIntervalRef.current = setInterval(checkIfMatched, 3000);
                return
              }

            /* problem */
            } catch (err) {
              console.error("Error searching for game:", err);
              setQueueMessage("Error searching for game");
              setIsSearching(false);
            }
    };

    /* visual */
    if (isMobile) {
        return (
            <div className="mobile-only"> 
                <div className="mobile-play-header"> 
                    <button className='mobile-dashboard-back-button' onClick={() => navigate('/')}>
                        <i className='bx bxs-left-arrow'></i>
                    </button>           
                    <div className='mobile-mult-header'>
                        multiplayer
                    </div>
                    <button className='mobile-profile-button' onClick={() => navigate('/play/random')}>
                        <i className='bx bxs-dice-6'></i>
                    </button>
                </div>

                <div className='mobile-mult-container'>
                    <div className='mobile-button-container'>
                        <div className="mobile-play-button" onClick={handleSearchGame}>
                            {isSearching ? "stop searching" : "search for a game!"}
                        </div>

                        <div className='mobile-queue-message'>
                            {queueMessage}
                        </div>
                        <div className='mobile-queue-subtext'>
                            {numPlayers} {numPlayers === 1 ? 'player' : 'players'} waiting for a crossword fight
                        </div>
                    </div>
                </div>

        </div> 
        );
    } else {
        return (
            <div>

                {/* header */}
                <div className='lb-header-container'>
                    <button
                        className='lb-back-button'
                        onClick={() => {
                            if (isSearching) {
                                handleLeaveQueue();
                            }
                            navigate('/');
                        }}
                    >
                        <i className='bx bxs-left-arrow'></i>
                    </button>      
                    <div className='lb-header'>multiplayer</div>
                </div>

                {/* queuing button */}
                <div className='mult-container'>
                    <div className='center-button-container'>
                        <div className="mult-play-button" onClick={handleSearchGame}>
                            {isSearching ? "stop searching" : "search for a game!"}
                        </div>

                        <div className='mult-queue-message'>
                            {queueMessage}
                        </div>
                        <div className='mult-queue-subtext'>
                            {numPlayers} {numPlayers === 1 ? 'player' : 'players'} waiting for a crossword fight
                        </div>
                    </div>
                </div>

            </div>    
        );
    }
}

export default Multiplayer;
