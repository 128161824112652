import React, { useState, useEffect } from 'react';
import '../style/custom-keyboard.css';

// Array of keys for the main keyboard layout (QWERTY)
const qwertyKeys = [
  'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P',
  'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L',
  'Z', 'X', 'C', 'V', 'B', 'N', 'M'
];

// Array of keys for the special characters/numeric keyboard
const specialCharKeys = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
  '_', '/', ':', ';', '(', ')', '$', '&', '@', 
  '"', '.', ',', '?', '!', '\'', '+', '-', '*', '~'
];

const CustomKeyboard = ({ onKeyPress, hasEnter, isCreate, isEditing }) => {
  const [isSpecialChar, setIsSpecialChar] = useState(false); // State to track current keyboard layout

  // Function to toggle between QWERTY and special character layouts
  const toggleKeyboardLayout = () => {
    setIsSpecialChar(!isSpecialChar);
  };

  // Effect to reset keyboard to QWERTY when entering edit mode
  useEffect(() => {
    if (isEditing) {
      setIsSpecialChar(false); // Reset to QWERTY when editing begins
    }
  }, [isEditing]);

  // Determine which keys to display based on the current layout
  const currentKeys = isSpecialChar ? specialCharKeys : qwertyKeys;

  // Determine dynamic className based on isCreate prop
  const keyboardClassName = `custom-keyboard ${isCreate ? 'create-mode' : 'play-mode'}`;

  return (
    <div className={keyboardClassName}>
      {/* First row of keys */}
      <div className="keyboard-row">
        {currentKeys.slice(0, 10).map((key) => (
          <button
            key={key}
            className="keyboard-key"
            onClick={() => onKeyPress(key)}
          >
            {key}
          </button>
        ))}
      </div>
      
      {/* Second row of keys */}
      <div className="keyboard-row">
        {currentKeys.slice(10, 19).map((key) => (
          <button
            key={key}
            className="keyboard-key middle-key"
            onClick={() => onKeyPress(key)}
          >
            {key}
          </button>
        ))}
      </div>

      {/* Third row of keys */}
      <div className="keyboard-row">
        {!isSpecialChar && ( // Only render rotate key in QWERTY mode
          <button
            className="keyboard-key rotate-key"
            onClick={() => onKeyPress('Shift')}
          >
            <box-icon color='white' name='rotate-right'></box-icon>
          </button>
        )}
        
        {currentKeys.slice(19).map((key) => (
          <button
            key={key}
            className="keyboard-key bottom-key"
            onClick={() => onKeyPress(key)}
          >
            {key}
          </button>
        ))}

        {hasEnter && !isSpecialChar ? ( // Only render enter keys in QWERTY mode
          <>
            <button
              className="keyboard-key enter-key"
              onClick={() => onKeyPress('Backspace')}
            >
              <i className='bx bxs-tag-x'></i>
            </button>
            <button
              className="keyboard-key enter-key"
              onClick={() => onKeyPress('Enter')}
            >
              <i className='bx bxs-arrow-from-left'></i>
            </button>
          </>
        ) : (
          !isSpecialChar && ( // Only render long enter key in QWERTY mode
            <button
              className="keyboard-key long-enter-key"
              onClick={() => onKeyPress('Backspace')}
            >
              <i className='bx bxs-tag-x'></i>
            </button>
          )
        )}
      </div>

      {/* Conditional fourth row for creation mode */}
      {isCreate && (
        <div className="keyboard-row">
          {/* Render "-" button if editing, otherwise special characters toggle button */}
          {isEditing ? (
            <button
              className="keyboard-key dash-key"
              onClick={() => onKeyPress('-')} // When editing, use "-" button
            >
              -
            </button>
          ) : (
            <button
              className="keyboard-key special-char-key"
              onClick={toggleKeyboardLayout} // Toggle between QWERTY and special characters
            >
              <div className='special-char-text'>{isSpecialChar ? 'ABC' : '123'}</div> {/* Display appropriate label based on layout */}
            </button>
          )}
          
          {/* Space bar */}
          <button
            className="keyboard-key space-bar-key"
            onClick={() => onKeyPress(' ')}
          >
            <i className='bx bx-space-bar'></i>          
          </button>

          {/* Render appropriate key based on mode */}
          {isSpecialChar ? (
            <button
              className="keyboard-key special-char-key"
              onClick={() => onKeyPress('Backspace')}
            >
              <i className='bx bxs-tag-x'></i> {/* Icon for backspace */}
            </button>
          ) : isEditing ? (
            <button
              className="keyboard-key dash-key"
              onClick={() => onKeyPress('-')} // Show dash when editing
            >
              -
            </button>
          ) : (
            <button
              className="keyboard-key return-key"
              onClick={() => onKeyPress('Return')}
            >
              <i className='bx bxs-arrow-from-left'></i> {/* Icon for return */}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomKeyboard;
