import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../config/environment';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setTokenState] = useState(localStorage.getItem('authToken'));
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  /* Set Token method */
  const setToken = (newToken) => {
    if (newToken) {
      localStorage.setItem('authToken', newToken);
    } else {
      localStorage.removeItem('authToken');
    }
    setTokenState(newToken);
  };

  /* update user function */
  const updateUser = (newUser) => {
    setUser(newUser);
  };

  /* Effect: Validate user whenever token changes */
  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) {
        setUser(null);
        setLoading(false);
        return;
      }

      setLoading(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      try {
        const response = await axios.get(`${apiUrl}/auth/profile`);
        setUser(response.data);
      } catch (err) {
        console.error('Error fetching profile:', err);
        setToken(null);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [token]);

  /* login a registered user */
  const login = async (username, password) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/auth/login`, { username, password });
      setToken(response.data.token);
      setUser(response.data.user);
      navigate('/');
    } catch (error) {
      console.error('Login error', error);
      alert('Login failed');
      setLoading(false);
    }
  };

  /* register a new user */
  const register = async (username, password) => {
    if (!username || !password) {
      alert('Username and Password are required');
      return;
    }
    try {
      await axios.post(`${apiUrl}/auth/register`, { username, password });
      navigate('/login');
    } catch (error) {
      console.error('Registration error:', error);
      alert('Registration failed');
    }
  };

  /* logout a signed-in user */
  const logout = () => {
    setToken(null);
    setUser(null);
    navigate('/login');
  };

  /* update color preference */
  const updateUserColorPreference = (newColorPreference) => {
    setUser((currentUser) => ({ ...currentUser, colorPreference: newColorPreference }));
  };

  return (
    <AuthContext.Provider value={{
      user,
      token,
      login,
      register,
      logout,
      updateUserColorPreference,
      updateUser,
      loading,
      setToken, /* Expose setToken clearly */
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
export default AuthContext;
