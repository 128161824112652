/* lib imports */
import React, { useState, useEffect, useContext } from 'react';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../config/environment';

/* auth context  */
import AuthContext from '../context/AuthContext';

/* style */
import '../style/auth.css';

/* register page */
const CompleteProfile = () => {

    const { user, updateUser } = useContext(AuthContext);
    const navigate = useNavigate();

    /* state variables */
    const [errors, setErrors] = useState({});
    const [tempErrorStyle, setTempErrorStyle] = useState(false);
    const [username, setUsername] = useState('');

    /* mobile handling */
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /* validate inputs */
    const validateInputs = () => {
        const errors = {};
        if (validator.isEmpty(username)) {
            errors.username = 'username is required';
        }
        return errors;
    };

    /* Function to update the username via the backend */
    const userSetUsername = async (username) => {
        try {
            const response = await fetch(`${apiUrl}/user/complete-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ username }),
            });
            if (!response.ok) {
                console.log("not OK: " + response)
                const data = await response.json();
                throw new Error(data.message || 'Failed to update profile');
            }
            const updatedUser = await response.json();
            console.log("updatedUser:" + updatedUser)
            return updatedUser;
        } catch (error) {
            console.log("not OK: " + error)
            console.error('Error updating username:', error);
            throw error;
        }
    };

    /* on submission */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateInputs();
        if (Object.keys(validationErrors).length > 0) {
            console.log("fail one")
            setErrors(validationErrors);
            setTempErrorStyle(true);
            setTimeout(() => setTempErrorStyle(false), 2000);
            return;
        }
        try {
            const updatedUser = await userSetUsername(username);
            console.log("out of userSetUsername")
            updateUser(updatedUser);
            console.log("out of updateUser")
            navigate('/');
        } catch (err) {
            const friendlyMessage = err.message.includes('taken')
              ? 'username taken'
              : 'an issue is ongoing, please try again later';
            setErrors({ username: friendlyMessage });
            setTempErrorStyle(true);
            setTimeout(() => setTempErrorStyle(false), 2000);
          }
    };

    /* visual */
    return (
        <div className='reg-ult-container'>
            {isMobile ? (

                /* mobile */
                <div className='mobile-auth-container'>

                    <div className='mobile-auth-header'> Choose Username </div>

                    <form onSubmit={handleSubmit}>

                        <div className='register-input-container'>
                            <input
                                className='mobile-username-input'
                                type="text"
                                placeholder="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>

                        {errors.username && (
                            <div className={`register-error-text ${tempErrorStyle ? 'temp-error' : ''}`}>
                                {errors.username}
                            </div>
                        )}

                        <button
                            type="submit"
                            className='mobile-register-submit-button'>
                            done
                        </button>
                    </form>
                </div>
            ) : (
                /* desktop */
                <div className='auth-container'>

                    <div className='auth-header'> Choose Username </div>

                    <form onSubmit={handleSubmit}>

                        <div className='register-input-container'>
                            <input
                                className='username-input'
                                type="text"
                                placeholder="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>

                        {errors.username && (
                            <div className={`register-error-text ${tempErrorStyle ? 'temp-error' : ''}`}>
                                {errors.username}
                            </div>
                        )}

                        <button
                            type="submit"
                            className='submit-button'>
                            done
                        </button>
                    </form>

                </div>
            )}
        </div>

    );
};

export default CompleteProfile;
