/* lib imports */
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* style */
import '../style/leaderboard.css';

/* leaderboard */
function Leaderboard() {

  /* get user */
  const { user } = useContext(AuthContext);

  /* create history object for navigation */
  const navigate = useNavigate(); 

  /* mobile handling */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* store leaderboard entries */
  const [leaderboardEntries, setLeaderboardEntries] = useState([]);
  
  /* fetch users on mount */
  useEffect(() => {
    async function fetchLeaderboardEntries() {
      try {
        const response = await fetch(`${apiUrl}/leaderboard/ultimate-leaderboard`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = `HTTP error! Status: ${response.status} - ${errorData.message || 'Unknown error'} - ${errorData.suggestion || ''}`;
          const errorDetails = `Error: ${errorData.error || 'No error message'}\nStack: ${errorData.stack || 'No stack trace'}\nName: ${errorData.name || 'Unknown'}\nCode: ${errorData.code || 'No error code'}`;
          throw new Error(`${errorMessage}\n\nDetails:\n${errorDetails}`);
        }
  
        const data = await response.json();
        setLeaderboardEntries(data);
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      }
    }
  
    fetchLeaderboardEntries();
  }, []);

  useEffect(() => {
    if (isMobile) {
      // Disables both vertical and horizontal scroll on mobile
      document.body.style.overflow = 'hidden';
    }
    return () => {
      // Reset overflow when the component unmounts
      document.body.style.overflow = 'auto';
    };
  }, [isMobile]);

  /* visual */
  if (isMobile) {
    return (
      <div className='mobile-leaderboard-container'>
        <div className='mobile-profile-header'>
          <button className='mobile-profile-back-button' onClick={() => navigate('/')}>
            <i className='bx bxs-left-arrow'></i>
          </button>           
          <h1>leaderboard</h1>
          <button className='mobile-profile-button' onClick={() => navigate('/play/random')}>
            <i className='bx bxs-dice-6'></i>
          </button>
        </div>
        <div className='mobile-lb-table-container'>
          <div className='mobile-lb-table-header'>
            <div className='mobile-rank-header'>rank</div>
            <div className='mobile-username-header'>username</div>
            <div className='mobile-sr-header'>sr</div>
          </div>
          {leaderboardEntries.map((entry, index) => (
            <div
              className={`mobile-lb-entry ${index === 0 ? 'gold' : index === 1 ? 'silver' : index === 2 ? 'bronze' : ''}`}
              key={index}
              onClick={() => navigate(`/profile/${entry.username}`)}
              style={{ cursor: 'pointer' }}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#e0e0e0"}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ""}
            >
              <div className='entry-rank-number'> 
                # {index + 1}
              </div>
              <div className='entry-username'>
                {entry.username}
              </div>
              <div className='entry-skill-rating'>
                {entry.skillRating}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        {/* header */}
        <div className='lb-header-container'>
          <button className='lb-back-button' onClick={() => navigate('/')}>
            <i className='bx bxs-left-arrow'></i>
          </button>      
          <div className='lb-header'> leaderboard </div>
        </div>
        {/* entries table */}
        <div className='lb-table-container'>
          <div className='lb-table-header'>
            <div className='mobile-rank-header'>rank</div>
            <div className='mobile-username-header'>username</div> {/* misnamed class and line above */}
            <div className='sr-header'>skill rating</div>
          </div>
          {leaderboardEntries.map((entry, index) => (
            <div
              className={`lb-entry ${index === 0 ? 'gold' : index === 1 ? 'silver' : index === 2 ? 'bronze' : ''}`}
              key={index}
              onClick={() => navigate(`/profile/${entry.username}`)}
              style={{ cursor: 'pointer' }}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#e0e0e0"}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ""}
            >
              <div className='entry-rank-number'> 
                # {index + 1}
              </div>
              <div className='entry-username'>
                {entry.username}
              </div>
              <div className='entry-skill-rating'>
                {entry.skillRating}
              </div>
            </div>
          ))}
        </div>
      </div>    
    );
  }
}

export default Leaderboard;
