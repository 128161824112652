/* lib imports */
import React, { useState , useEffect, useContext} from 'react';
import { formatTime } from '../pages/Play';
import Rating from '../components/Rating'

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* context */
import AuthContext from '../context/AuthContext';

/* style */
import '../style/puzzle-complete.css';

/* represents the popup after completing a puzzle */
function PuzzleComplete({ onClose, timer, puzzleId, didSubmit, ratingAdjustment, crossword, isMobile }) {

  /* contains top 10 leaderboard entries for this puzzle, as well as overall rank */
  const [leaderboardData, setLeaderboardData] = useState({ entries: [], rank: null });

  /* rating */
  const [rating, setRating] = useState(0);
  const [isRatingSubmitted, setIsRatingSubmitted] = useState(false);

  /* get the user */
  const { user } = useContext(AuthContext);

  /* get leaderboard entries and populate our state var */
  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await fetch(`${apiUrl}/leaderboard/get-10/${puzzleId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
          body: JSON.stringify({ userId: user._id })
        });
        if (!response.ok) {
          throw new Error('Failed to fetch leaderboard entries');
        }
        const data = await response.json();
        setLeaderboardData(data);
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };    
  
    /* get leaderboard */
    fetchLeaderboard();

  }, [puzzleId, user._id]);

  /* on mount */
  useEffect(() => {
    /* determine if a rating has been applied */
    if (crossword.ratings) {
      const hasRated = crossword.ratings.some(r => r.userId === user._id);
      setIsRatingSubmitted(hasRated);
    }
  }, [])

  /* submit a rating to the puzzle */
  const submitRating = async (puzzleId, userId, rating) => {
    try {
      const response = await fetch(`${apiUrl}/puzzle/rate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          puzzleId,
          userId,
          rating
        })
      });
  
      if (!response.ok) {
        const message = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, ${message}`);
      }
  
      const result = await response.json();
      console.log('Rating submitted successfully:', result);
      return result;
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };  
  
  /* returns the leftover milliseconds of a leaderboard time */
  function getMilliseconds(time) {
    return time % 1000;
  }

  const timerContainerClass = isMobile ? 'timer-mobile-pc-container' : 'timer-container-def'

  /* visual */
  return (
    <div className="overlay">
      <div className="popup-banner">
        <div className='banner-header'>COMPLETE!</div>

        <div className={timerContainerClass}>
          {timer === 9999999 ? (
            <button className='pc-help-button'>
              <i className='bx bxs-donate-heart'></i>
            </button>          
          ) : (
            <>
              <div className='timer-banner'>{formatTime(timer)}</div>
              <span className="pc-milliseconds">{getMilliseconds(timer).toString().padStart(3, '0')}</span>
            </>
          )}
        </div>

        {!didSubmit && (
          <div className="first-completion-message">
            only your first completion is entered
          </div>
        )}

        <div className='your-rank-container'>
          <div className='your-rank-text'>your rank</div>

          <div className='rank-rating-container'>
            <div className='rank-text'>#{leaderboardData.rank}</div>
            <div className='new-skill-rating'>
              {(ratingAdjustment === -1 || ratingAdjustment === -2) ? "-- SR" : 
                (ratingAdjustment >= 0 ? `+${ratingAdjustment} SR` : `${ratingAdjustment} SR`)}
            </div>
          </div>

        </div>

        {leaderboardData.entries.length > 0 && (
          <div className="leaderboard-container">
            <h3>best times</h3>
            <div className="leaderboard-list">
              {leaderboardData.entries.map((entry, index) => (
                <div key={entry._id} className="leaderboard-entry">
                  <div className='rank-header'>
                    <span className="rank-symbol">#</span><span className="rank-number">{index + 1}</span>
                  </div>
                  <div className="username">{entry.userId.username}</div>
                  <div className="time">
                    {entry.completionTime === 9999999 ? (
                      <span className="formatted-time">--</span>
                    ) : (
                      <>
                        <span className="formatted-time">{formatTime(entry.completionTime)}</span>
                        <span className="milliseconds">{getMilliseconds(entry.completionTime).toString().padStart(3, '0')}</span>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {!isRatingSubmitted && (
          <div>
            <div className="rating-section">
              <h4>RATE THIS PUZZLE</h4>
              <Rating count={5} value={rating} onRating={setRating} />
            </div>
          </div>
        )}

        <button className='pc-close-button' onClick={() => {
            /* submit rating if valid */
            if (rating > 0) {
              submitRating(puzzleId, user._id, rating);
              setIsRatingSubmitted(true);
            }
            onClose();
          }
        }>Close</button>
      </div>
    </div>
  );
}

export default PuzzleComplete;