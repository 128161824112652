/* lib imports */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import 'boxicons';

/* user information */
import AuthContext from '../context/AuthContext'; 

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* component imports */
import CrosswordGrid from '../components/CrosswordGrid';
import Alert from '../components/Alert';
import SuccessPopup from '../components/SuccessPopup';
import CustomKeyboard from '../components/CustomKeyboard';

/* style */
import '../style/create.css';
import '../style/dashboard.css';
import '../style/custom-keyboard.css';

/* initial blank grid */
const initialGrid = Array(5).fill(null).map(() => Array(5).fill(''));

/* Create page component */
function Create() {

  /* if we should be displaying instructions */
  const [displayInstruction, setDisplayInstruction] = useState(true);

  /* highlighted word values */
  const [wordValues, setWordValues] = useState([]);
  const [completeWords, setCompleteWords] = useState([]);
  const [loadingCompleteWords, setLoadingCompleteWords] = useState(false);

  /* current focus, directional vars */
  const [selectedCell, setSelectedCell] = useState({ col: 0, row: 0 });
  const [direction, setDirection] = useState(0); /* { 0:across, 1:down } */

  /* track consecutive backspace presses */
  const [isBackspacePressed, setIsBackspacePressed] = useState(false);

  /* mobile handling */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
      /* set focus to 0, 0 */
      handleCellFocus(0, 0)
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
  }, []);

  /* readonly */
  if (isMobile) {
    const editableDivs = document.querySelectorAll('div[contenteditable="true"]');
    editableDivs.forEach(div => {
      div.classList.add('mobile-read-only');
    });
  }

  /* defines the currently selected word */
  const [selectedWord, setSelectedWord] = useState({
    selectedWordDirection: null,
    cells: []  
  });

  /* error handling prior to grid submission  */
  const [errorMessage, setErrorMessage] = useState('def_err_msg');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  /* text field error handling */
  const [errors, setErrors] = useState({
    title: '',
    hints: {}
  });

  /* input focusing */
  const [activeInput, setActiveInput] = useState('grid');
  const [selectedClue, setSelectedClue] = useState({ wordNumber: null, wordDirection: null });

  /* create history object for navigation */
  const navigate = useNavigate(); 

  /* get the user so we can add the puzzle to their profile */
  const { user } = useContext(AuthContext);

  /* grid being modified by user */
  const [grid, setGrid] = useState(initialGrid);

  /* hints */
  const [hints, setHints] = useState({});

  /* title */
  const [title, setTitle] = useState('');

  /* words, and if we should show them for hint submission */
  const [words, setWords] = useState([]);
  const [acrossWords, setAcrossWords] = useState([]);
  const [downWords, setDownWords] = useState([]);
  const [showWords, setShowWords] = useState(false);

  /* describes if we are in edit mode*/
  const [isEditing, setIsEditing] = useState(true); 

  /* track numbered cells */
  const [numberedCells, setNumberedCells] = useState([]);
  
  /* handle successful submission popup */
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);  

  /* fetch complete wods */
  const fetchCompleteWords = async (pattern) => {
    /* loading... */
    setLoadingCompleteWords(true);

    /* hit our endpoint to get words that complete the selected word */
    try {
      const response = await fetch(`${apiUrl}/word/get-complete-words`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },     
        body: JSON.stringify({ chars: pattern })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data; 
    } catch (error) {
      console.error('Error fetching complete words:', error);
      return [];
    } finally {
      /* done loading */
      setLoadingCompleteWords(false); 
    }
  };

  /* refocus to a particular cell */
  const focusCell = (row, col) => {
    const cellElement = document.querySelector(`[data-row='${row}'][data-col='${col}']`);
    if (cellElement) {
      cellElement.focus();
    }
  };

  /* handle selecting a complete word */
  const handleCompleteWordClick = (word) => {
    const newGrid = [...grid];
    const wordChars = word.split('');

    const uniqueCells = Array.from(new Set(selectedWord.cells.map(cell => JSON.stringify(cell)))).map(cell => JSON.parse(cell));

    uniqueCells.forEach((cell, index) => {
      if (wordChars[index]) {
        newGrid[cell.y][cell.x] = wordChars[index].toUpperCase();
      }
    });

    setGrid(newGrid);
  };

  /* title change */
  const handleMobileTitleChange = (event) => {
    const currentTitle = title || '';
    let newTitle = currentTitle;

    // Retrieve the input value or special key from the event target
    const input = event.target.value;

    // Check for special keys: Backspace or Shift
    if (input === 'Backspace') {
      // Handle backspace functionality
      // Remove the last character from newTitle
      newTitle = currentTitle.slice(0, -1);

    } else if (input === 'Shift') {
      // Handle shift functionality
      // This example assumes we toggle capitalization for the next character input
      // Similar to the clue change function, Shift is acknowledged but no direct action here
      // If needed, implement shift handling logic based on your requirements

    } else {
      // Handle regular character input
      // Add the character to newTitle
      const charToAdd = input.length === 1 ? input.toLowerCase() : ''; // Ensure only valid characters are added
      newTitle += charToAdd;
    }

    setTitle(newTitle);

    // Perform validation and update the errors state
    if (!newTitle.trim()) {
      setErrors(prev => ({ ...prev, title: "title cannot be empty" }));
    } else if (newTitle.length > 80) {
      setErrors(prev => ({ ...prev, title: "title must be less than 80 characters" }));
    } else {
      setErrors(prev => ({ ...prev, title: '' }));
    }
  };

  /* title change */
  const handleTitleChange = (event) => {
    let newCharacter = '';
    const currentTitle = title || '';
    let newTitle = currentTitle;
  
    if (event.type === 'keydown') {
      // Handling key press events
      newCharacter = event.key;
  
      if (newCharacter === 'Backspace') {
        newTitle = currentTitle.slice(0, -1);
      } else if (newCharacter.length === 1) { // Ensure it is a single character key
        const charToAdd = event.shiftKey ? newCharacter.toUpperCase() : newCharacter.toLowerCase();
        newTitle += charToAdd;
      }
  
    } else if (event.type === 'change') {
      // Handling change events
      newTitle = event.target.value;
    }
  
    setTitle(newTitle);
  
    // Perform validation and update the errors state
    if (!newTitle.trim()) {
      setErrors(prev => ({ ...prev, title: "title cannot be empty" }));
    } else if (newTitle.length > 80) {
      setErrors(prev => ({ ...prev, title: "title must be less than 80 characters" }));
    } else {
      setErrors(prev => ({ ...prev, title: '' }));
    }
  };
  

  /* handle deletions in the grid */
  const handleKeyDown = (e, rowIndex, colIndex) => {
    console.log("keydown")

    setShowErrorMessage(false);
  
    if (activeInput === 'grid') {
      console.log("grid")
      if (['Backspace', 'Tab', ' ', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Shift', 'Enter'].includes(e.key) && !isMobile) {
        e.preventDefault();
      }
  
      if (e.key === 'Backspace') {
        console.log("here")
        handleCellUpdate(rowIndex, colIndex, '');
        if (grid[rowIndex][colIndex] === ' ' || grid[rowIndex][colIndex] === '') {
          onPrevCell(rowIndex, colIndex);
          setIsBackspacePressed(true);
        } else if (isBackspacePressed) {
          onPrevCell(rowIndex, colIndex);
        } else {
          setIsBackspacePressed(true);
        }
      } else if (e.key === ' ') {
        handleCellUpdate(rowIndex, colIndex, '');
        onNextCell(rowIndex, colIndex);
      } else if (e.key === 'Shift') {
        const newDirection = direction === 0 ? 1 : 0;
        setDirection(newDirection);
      } else if ((e.key.length === 1 && /^[a-zA-Z]$/.test(e.key) || e.key === '-') && isMobile) {
        handleCellUpdate(rowIndex, colIndex, e.key.toUpperCase());
        console.log("adding mobile chars");
        onNextCell(rowIndex, colIndex);
      }
      else if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        onArrowClick(e.key);
      } else {
        setIsBackspacePressed(false);
      }
    } else if (activeInput === 'clue' && isMobile) {
      console.log("clue")
      handleMobileClueChange({ target: { value: e.key } }, selectedClue.wordNumber, selectedClue.wordDirection);
    } else if (activeInput === 'title' && isMobile) {
      console.log("title")
      handleMobileTitleChange({ target: { value: e.key } });
    }
  };
  

  /* grid cell update */
  const handleCellUpdate = (rowIndex, colIndex, newValue) => {

    /* update grid */
    const newGrid = grid.map((row, rIdx) => 
      row.map((cell, cIdx) => (rIdx === rowIndex && cIdx === colIndex ? newValue : cell))
    );

    /* only toggle off if not a backspace */
    if (newValue) {
      /* turn off instructions */
      setDisplayInstruction(false)
    }

    /* set new grid */
    setGrid(newGrid);
  };

  /* clue change */
  const handleClueChange = (event, wordNumber, wordDirection) => {
    const hintKey = `${wordNumber}-${wordDirection}`;
    
    // Get the current value of the hint
    const currentHint = hints[hintKey] || '';
    let newHint = currentHint;
  
    console.log("clue change");
    console.log(event);
  
    if (event.type === 'keydown') {
      let newCharacter = event.key;
  
      if (newCharacter === 'Backspace') {
        newHint = currentHint.slice(0, -1);
      } else if (newCharacter.length === 1) { // Ensure it's a single character key
        newCharacter = newCharacter.toLowerCase();
        newHint += newCharacter;
      }
    } else if (event.type === 'change') {
      // Use the current value of the input field
      newHint = event.target.value;
      console.log("New input value: ", newHint);
    }
  
    // Update the hints in the state
    const updatedHints = { ...hints, [hintKey]: newHint };
    setHints(updatedHints);
  
    // Perform validation and update the errors state
    if (!newHint.trim()) {
      // Set error if the clue is empty
      setErrors(prev => ({
        ...prev,
        hints: { ...prev.hints, [hintKey]: "Clue cannot be empty" }
      }));
    } else if (newHint.length > 80) { 
      // Set error if the clue is too long
      setErrors(prev => ({
        ...prev,
        hints: { ...prev.hints, [hintKey]: "Clue must be less than 80 characters" }
      }));
    } else {
      // Remove error for this clue if validation passes
      setErrors(prev => ({
        ...prev,
        hints: { ...prev.hints, [hintKey]: "" }
      }));
    }
  };
  

  const handleMobileClueChange = (event, wordNumber, wordDirection) => {
    const hintKey = `${wordNumber}-${wordDirection}`;
    
    // Get the current value of the hint
    const currentHint = hints[hintKey] || '';
    let newHint = currentHint;

    console.log("newHint", newHint)
  
    // Retrieve the input value or special key from the event target
    const input = event.target.value;
  
    // Check for special keys: Backspace, Shift, or Return
    if (input === 'Backspace') {
      // Handle backspace functionality
      newHint = currentHint.slice(0, -1);
  
    } else if (input === 'Shift') {
      // Handle shift functionality
      // Example logic to toggle capitalization could be added here
  
    } else if (input === 'Return') {
      // Handle return functionality
      // Change focus to the next hint
      focusNextHint(wordNumber, wordDirection);
  
    } else {
      // Handle regular character input
      const charToAdd = input.length === 1 ? input.toLowerCase() : ''; // Ensure only valid characters are added
      newHint += charToAdd;
    }
  
    console.log("Updated newHint:", newHint);
  
    // Update the hints in the state
    const updatedHints = { ...hints, [hintKey]: newHint };
    setHints(updatedHints);
  
    // Perform validation and update the errors state
    if (!newHint.trim()) {
      // Set error if the clue is empty
      setErrors(prev => ({
        ...prev,
        hints: { ...prev.hints, [hintKey]: "clue cannot be empty" }
      }));
    } else if (newHint.length > 80) { 
      // Set error if the clue is too long
      setErrors(prev => ({
        ...prev,
        hints: { ...prev.hints, [hintKey]: "clue must be less than 80 characters" }
      }));
    } else {
      // Remove error for this clue if validation passes
      setErrors(prev => ({
        ...prev,
        hints: { ...prev.hints, [hintKey]: "" }
      }));
    }
  };
  
  const focusNextHint = (currentWordNumber, currentDirection) => {
    // Determine the current list of words to traverse
    const allWords = [...acrossWords, ...downWords];
  
    // Find the index of the currently selected word
    const currentIndex = allWords.findIndex(word => word.number === currentWordNumber && word.direction === currentDirection);
    console.log("currentIndex", currentIndex)
  
    let nextWord;
    
    if (currentIndex === -1 || currentIndex === allWords.length - 1) {
      // If the current word is not found or it's the last in the list, wrap to the first word
      nextWord = acrossWords[0]; // Start from the first across word
    } else {
      // Otherwise, go to the next word in the combined list
      nextWord = allWords[currentIndex + 1];
    }
  
    // Extract the next word's details
    const nextWordNumber = nextWord.number;
    const nextDirection = nextWord.direction;
  
    console.log("acrossWords:", acrossWords);
    console.log("downWords:", downWords);
    console.log("words:", words);
    console.log("Next Word:", nextWord);
    
    // Update the selected clue state
    setSelectedClue({ wordNumber: nextWordNumber, direction: nextDirection });
  
    // Attempt to focus the next input field
    const nextInput = document.getElementById(`hint-input-${nextWordNumber}-${nextDirection}`);
    
    if (nextInput) {
      nextInput.focus(); // Only focus if the input exists
    } else {
      console.warn(`Element with ID hint-input-${nextWordNumber}-${nextDirection} not found.`);
    }
  };
  
  
  
  /* handle focusing a cell */
  const handleCellFocus = (x, y) => {

    /* editing grid */
    setActiveInput('grid')

    /* remove error */
    setShowErrorMessage(false)

    /* intercept - cell focus */
    if (grid[y][x] === '-') {
      setSelectedWord({
        selectedWordDirection: null,
        cells: []
      });
      setSelectedCell({x: x, y: y})
      setCompleteWords([]);
      return;
    }

    /* set focus to valid cell */
    if (selectedCell.x === x && selectedCell.y === y) {
      setDirection(prevDirection => 1 - prevDirection);
    } else {
      setSelectedCell({ x, y });
    }
  };

  /* handle arrow key navigation with advanced wrapping and skipping over blocked cells */
  const onArrowClick = (arrowDirection) => {
    const numRows = grid.length;
    const numCols = grid.length;
    let nextRow = selectedCell.y;
    let nextCol = selectedCell.x;
    let attempts = 0;

    const incrementIndex = (index, max, direction) => {
      if (direction === 'increase') {
        return (index + 1) % max;
      } else {
        return (index - 1 + max) % max;
      }
    };

    while (attempts < numRows * numCols) {  
      switch (arrowDirection) {
        case 'ArrowRight':
          nextCol = incrementIndex(nextCol, numCols, 'increase');
          break;
        case 'ArrowLeft':
          nextCol = incrementIndex(nextCol, numCols, 'decrease');
          break;
        case 'ArrowDown':
          nextRow = incrementIndex(nextRow, numRows, 'increase');
          break;
        case 'ArrowUp':
          nextRow = incrementIndex(nextRow, numRows, 'decrease');
          break;
        default:
          console.log("Unsupported arrow direction");
          return;
      }

      // Check if the new position is valid
      if (grid[nextRow][nextCol] !== '-') {
        // Set selected cell and focus
        setSelectedCell({ x: nextCol, y: nextRow });
        focusCell(nextRow, nextCol);
        return;
      }

      // Increment the attempts counter
      attempts += 1;
    }
  };

  /* return a 'word' with highlighted cells in the opposite direction of our state var */  
  function otherDirectionWord(x, y) {
    const result = {
      direction: direction === 0 ? 'down' : 'across',
      cells: []
    };
  
    const rows = grid.length;
    const cols = grid[0].length;
  
    /* across */
    if (direction === 0) { 
      for (let row = x; row >= 0; row--) {
        if (grid[y][row] === '*' || grid[y][row] === '-') break;
        result.cells.unshift({ x: row, y: y });
      }
      for (let row = x + 1; row < rows; row++) {
        if (grid[y][row] === '*' || grid[y][row] === '-') break;
        result.cells.push({ x: row, y: y });
      }
      
    /* down */
    } else if (direction === 1) {
      for (let col = y; col >= 0; col--) {
        if (grid[col][x] === '*' || grid[col][x] === '-') break;
        result.cells.unshift({ x: x, y: col });
      }
      for (let col = y; col < cols; col++) {
        if (grid[col][x] === '*' || grid[col][x] === '-') break;
        result.cells.push({ x: x, y: col });
      }
    }
    return result;
  }

  /* handle auto moving focus when typing consecutive letters */
  const onNextCell = (row, col) => {
    /* init with our current position */
    let nextRow = row;
    let nextCol = col;
    /* across */
    if (direction === 0) { 
      if (col + 1 < grid[row].length) {
        nextCol = col + 1;
      }
    /* down */
    } else {
      if (row + 1 < grid.length) {
        nextRow = row + 1;
      }
    }

    /* set selected cell and focus */
    setSelectedCell({ x: nextCol, y: nextRow });
    focusCell(nextRow, nextCol);
  };

  /* handle auto moving focus when typing consecutive letters */
  const onPrevCell = (row, col) => {
    /* init with our current position */
    let prevRow = row;
    let prevCol = col;
    /* across */
    if (direction === 0) { 
        if (col - 1 >= 0) {
            prevCol = col - 1;
        }
    /* down */
    } else { 
        if (row - 1 >= 0) {
            prevRow = row - 1;
        }
    }
    /* set selected cell and focus */
    setSelectedCell({ x: prevCol, y: prevRow });
    focusCell(prevRow, prevCol);
  };

  /* when focus is changed */
  useEffect(() => {
    /* do not run on mount */
    if (selectedCell.x === -1) {
      return
    }

    /* get the new word */
    var newSelection = otherDirectionWord(selectedCell.x, selectedCell.y)

    /* potentially at an invalid square here, exit if we find invalid word */
    if (!newSelection) {
      return
    }

    /* update our selectedWord */
    setSelectedWord({ 
      selectedWordDirection: newSelection.direction,
      cells: newSelection.cells
    });
  }, [selectedCell, isBackspacePressed, grid]);

  /* update our filtered words when words changes */
  useEffect(() => {
    /* set our filtered words */
    setAcrossWords(words.filter(word => word.direction === 0).sort((a, b) => a.number - b.number));
    setDownWords(words.filter(word => word.direction === 1).sort((a, b) => a.number - b.number));
  }, [words])

  /* when the direction is changed */
  useEffect(() => {
    /* exit early if crossword not loaded */
    if (!grid) {
      return
    }

    /* select a word with our new direction */
    var newSelection = otherDirectionWord(selectedCell.x, selectedCell.y)

    /* update our selectedWord */
    setSelectedWord({ 
      selectedWordDirection: newSelection.direction,
      cells: newSelection.cells
    });
  }, [direction])

  /* on selecting a new word, get its values */
  useEffect(() => {
    if (selectedWord && selectedWord.cells) {

      /* track unique cells */
      const seen = new Set();
     
      /* filter out duplicate cells */
      const uniqueCells = selectedWord.cells.filter(cell => {
        const coord = `${cell.x},${cell.y}`;
        if (seen.has(coord)) {
          return false;
        }
        seen.add(coord);
        return true;
      });

      /* gather values */
      const values = uniqueCells.map(cell => {
        return grid[cell.y] && grid[cell.y][cell.x] ? grid[cell.y][cell.x] : '';
      });

      /* get the words that complete this one */
      if (values.length) {
        fetchCompleteWords(values).then(completeWords => {
          setCompleteWords(completeWords);
        }).catch(error => {
          console.error("Fetch Complete Words Error:", error);
        });
      }

      /* set the values */
      setWordValues(values); 

    } else {

      /* set blank vals */
      setWordValues([]);
      setCompleteWords([]);
    }
  },  [selectedWord]);

  /* resets the grid */
  const resetBoard = () => {
    setGrid(initialGrid)
    setDisplayInstruction(true)
    setNumberedCells([])
    setHints([])
    setWords([])
    setIsEditing(true)
    setShowWords(false)
    setShowErrorMessage(false)
    setSelectedWord({ 
      selectedWordDirection: -1,
      cells: []
    }); 
    setErrors({
      title: '',
      hints: {}
    });
  }

  /* on submittal of crossword */
  const submitCrossword = async () => {

    let hasErrors = false;

    /* title required */
    if (!title || title.trim() === '') {
      setErrors(prev => ({ ...prev, title: "you must enter a title for your crossword" }));
      hasErrors = true;
    } else {
      setErrors(prev => ({ ...prev, title: '' }));
    }
  
    /* hints required */
    let newHintsErrors = {};
    const wordsWithHints = words.map(word => {
      const hintKey = `${word.number}-${word.direction}`;
      const hint = hints[hintKey];
      if (!hint || hint.trim() === '') {
        newHintsErrors[hintKey] = `hint is required`;
        hasErrors = true;
      }
      return { ...word, hint };
    });
  
    /* exit if empty hint */
    if (wordsWithHints.includes(null)) {
      hasErrors = true; 
      return;
    }

    /* handle errors if they appear */
    setErrors(prev => ({ ...prev, hints: newHintsErrors }));
    if (hasErrors) {
      return;
    }

    /* reset selected word */
    setSelectedWord({ 
      selectedWordDirection: -1,
      cells: []
    });  

    /* payload */
    const crosswordData = {
      title: title,
      date: new Date().toISOString().split('T')[0],
      words: wordsWithHints,
      grid,
    };

    /* proceed with insertion into database */
    try {
      /* hit our endpoint to add to puzzles db, include payload in body */
      const response = await fetch(`${rootUrl}/puzzles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(crosswordData),
      });
  
      /* handle fail response */
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      /* success! get response and acknowledge */
      const result = await response.json();
      console.log('Crossword puzzle submitted successfully:', result);

      /* update the user puzzlesCreated field */
      try {
        const userUpdateResponse = await fetch(`${apiUrl}/user/updatePuzzles`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
          body: JSON.stringify({ userId: user._id, puzzleId: result._id }),
        });

        if (!userUpdateResponse.ok) {
          throw new Error(`Error updating user: ${userUpdateResponse.statusText}`);
        }

        const userUpdateResult = await userUpdateResponse.json();
        console.log('User updated successfully:', userUpdateResult);
      } catch (error) {
        console.error('Error updating user:', error);
        alert('Error updating user. Please try again.');
      }
  
      /* reset the board to prevent further submissions */
      resetBoard();

      /* show success modal */
      setShowSuccessPopup(true);

    /* fail case, acknoledge */
    } catch (error) {
      console.error('Error submitting crossword puzzle:', error);
      alert('Error submitting crossword puzzle. Please try again.');
    }
  };  

  /* Number the grid */
  const numberGrid = (grid) => {
    let number = 1;
    const numberedCells = [];

    for (let y = 0; y < grid.length; y++) {
      for (let x = 0; x < grid[y].length; x++) {
        if (grid[y][x] !== '*' && grid[y][x] !== '') {
          const isStartOfHorizontal = (x === 0 || grid[y][x - 1] === '*' || grid[y][x - 1] === '') && (x + 1 < grid[y].length && grid[y][x + 1] !== '*' && grid[y][x + 1] !== '');
          const isStartOfVertical = (y === 0 || grid[y - 1][x] === '*' || grid[y - 1][x] === '') && (y + 1 < grid.length && grid[y + 1][x] !== '*' && grid[y + 1][x] !== '');

          if (isStartOfHorizontal || isStartOfVertical) {
            numberedCells.push([number, { x, y }]);
            number++;
          }
        }
      }
    }
    return numberedCells;
  };

  /* extract words from the grid */
  const extractWords = (grid) => {

    /* reset */
    const words = [];
    setWords([]);

    /* gather horizontal */
    for (let y = 0; y < grid.length; y++) {
      let word = '';
      let startX = null;
      for (let x = 0; x < grid[y].length; x++) {
        const cell = grid[y][x];
        if (cell !== '' && cell !== '*' && cell !== '-'  ) {
          if (startX === null) startX = x;
          word += cell;
        } else {
          if (word.length > 1) {
            /* current val of startX for consistency */
            const safeStartX = startX; 
            words.push({
              number: -1,
              direction: 0,
              hint: '',
              answer: word,
              length: word.length,
              cells: Array.from(word).map((char, idx) => ({ x: safeStartX + idx, y, char })),
            });
          }
          word = '';
          startX = null;
        }
      }
      if (word.length > 1) {
        words.push({
          number: -1,
          direction: 0,
          hint: '',
          answer: word,
          length: word.length,
          cells: Array.from(word).map((char, idx) => ({ x: startX + idx, y, char })),
        });
      }
    }

    /* gather vertical */
    for (let x = 0; x < grid[0].length; x++) {
      let word = '';
      let startY = null;
      for (let y = 0; y < grid.length; y++) {
        const cell = grid[y][x];
        if (cell !== '' && cell !== '*' && cell !== '-'  ) {
          if (startY === null) startY = y;
          word += cell;
        } else {
          if (word.length > 1) {
            /* current val of startX for consistency */
            const safeStartY = startY; 
            words.push({
              number: -1,
              direction: 1,
              hint: '',
              answer: word,
              length: word.length,
              cells: Array.from(word).map((char, idx) => ({ x, y: safeStartY + idx, char })),
            });
          }
          word = '';
          startY = null;
        }
      }
      if (word.length > 1) {
        words.push({
          number: -1,
          direction: 1,
          hint: '',
          answer: word,
          length: word.length,
          cells: Array.from(word).map((char, idx) => ({ x, y: startY + idx, char })),
        });
      }
    }

    return words;
  };

  /* assign numbers to words based on numbered cells */
  const assignNumbers = (words, numberedCells) => {
    return words.map(word => {
      const firstCell = word.cells[0];
      const numberInfo = numberedCells.find(numCell => numCell[1].x === firstCell.x && numCell[1].y === firstCell.y);
      if (numberInfo) {
        return { ...word, number: numberInfo[0] };
      }
      return word;
    });
  };

  /* handle initial crossword grid submission */
  const handleSubmitWords = () => {

    /* extract words from the submitted grid */
    const words = extractWords(grid);

    /* validate puzzle */
    let hasAcross = false;
    let hasDown = false;
    for (let i = 0; i < words.length; i++) {
        if (words[i].direction === 0) {  /* across */
            hasAcross = true;
        }
        if (words[i].direction === 1) {  /* down */
            hasDown = true;
        }
        if (hasAcross && hasDown) {
            break;
        }
    }

    /* alert if invalid */
    if (!hasAcross) {
        /* error handling */
        setErrorMessage("you must have at least one across word")
        setShowErrorMessage(true)
        return;
    }
    if (!hasDown) {
        /* error handling */
        setErrorMessage("you must have at least one down word")
        setShowErrorMessage(true);
        return;
    }

    /* success, ensure no errors shown */
    setShowErrorMessage(false)

    /* reset selected word and cell */
    setSelectedWord({ 
      selectedWordDirection: -1,
      cells: []
    });  
    setSelectedCell({
      col: -1,
      row: -1
    })

    /* set words */
    setWords(words)
    /* show list of generated words */
    setShowWords(true)

    /* formalize the grid by replacing blank cells with black cells */
    const formalizedGrid = grid.map(row => 
      row.map(cell => (cell === '' || cell === '-' ? '*' : cell))
    );    
    /* update displayed grid */
    setGrid(formalizedGrid)

    /* extract numbered cells */
    const numberedCells = numberGrid(formalizedGrid);
    /* set numbered cells */
    setNumberedCells(numberedCells);

    /* assign numbers to words */
    const numberedWords = assignNumbers(words, numberedCells);
    /* set words */
    setWords(numberedWords);

    /* no longer editing */
    setIsEditing(false);
  };

  /* handle edit mode */
  const handleEdit = () => {

    /* reset black cells */
    const userGrid = grid.map(row => row.map(cell => (cell === '*' ? '-' : cell)));
    setGrid(userGrid);

    /* reset numbered cells */
    setNumberedCells([])

    /* we are editing! */
    setIsEditing(true); 
    setShowWords(false);
  };

  /* visual */
  return (
    <div>
      {isMobile ? (
        /* mobile */
        <div className='mobile-create-container'> 

            {showSuccessPopup && <SuccessPopup onClose={() => setShowSuccessPopup(false)} />}

            <div className="mobile-create-header">
              <button className='mobile-create-back-button' onClick={() => navigate('/')}>
                  <i className='bx bxs-left-arrow'></i>
              </button>     

              <h1>create</h1>

              {isEditing ? (
                <button className='mobile-edit-button' onClick={handleSubmitWords}>
                  <i className='bx bxs-archive-out'></i>
                </button>
              ) : (
                <button className='mobile-edit-button' onClick={handleEdit}>
                  <i className='bx bxs-edit'></i>
                </button>
              )}
            </div>

            <div className='mobile-grid-container'>
              <CrosswordGrid
                grid={grid}
                selectedWord={selectedWord}
                selectedCell={selectedCell}
                handleKeyDown={handleKeyDown}
                numberedCells={numberedCells}
                onCellUpdate={handleCellUpdate}
                onCellFocus={handleCellFocus}
                onNextCell={onNextCell}
                onPrevCell={onPrevCell}
                blackEditable={true}
                canFocus={isEditing}
                enforceBlack={!isEditing}
                allowDashes={true}
              />
            </div>

            {showWords && (
              <div className='mobile-word-hint-outer-container'>
                <div className='mobile-word-hint-container'>
                  
                  {/* Words Section */}
                  <div className='mobile-create-words'>
                    <div className='mobile-across-words-container'>
                      <div className='mobile-create-subheading'>across</div>
                      <ul className='across-words'>
                        {acrossWords.map((word) => (
                          <li key={`${word.number}-${word.direction}`}>
                            <span className='mobile-word-number'>{word.number}:</span>
                            <span className='mobile-word-text'>{word.answer}</span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className='mobile-across-words-container'>
                      <div className='mobile-create-subheading'>down</div>
                      <ul className='down-words'>
                        {downWords.map((word) => (
                          <li key={`${word.number}-${word.direction}`}>
                            <span className='mobile-word-number'>{word.number}:</span>
                            <span className='mobile-word-text'>{word.answer}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {/* Clues Section */}
                  <div className='create-clues'>
                    {/* Across Clues */}
                    <ul className='mobile-across-clues'>
                      {acrossWords.map((word) => (
                        <li key={`${word.number}-${word.direction}`}>
                          <div className='mobile-hint-input-container'>
                            <input
                              type='text'
                              value={hints[`${word.number}-${word.direction}`] || ''}
                              className='mobile-clue-input'
                              id={`hint-input-${word.number}-${word.direction}`}
                              placeholder={`clue for ${word.answer}`}
                              onChange={(e) => handleClueChange(e, word.number, word.direction)}
                              onFocus={() => {
                                setActiveInput('clue');
                                setSelectedClue({ wordNumber: word.number, wordDirection: 0});
                              }}
                              readOnly={true}
                            />

                            {errors.hints[`${word.number}-${word.direction}`] && (
                              <div className="error-icon">
                                <i className='bx bxs-error-circle'></i>
                                <div className="mobile-error-message">{errors.hints[`${word.number}-${word.direction}`]}</div>
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>

                    {/* Down Clues */}
                    <ul className='mobile-down-clues'>
                      {downWords.map((word) => (
                        <li key={`${word.number}-${word.direction}`}>
                          <div className='mobile-hint-input-container'>
                            <input
                              type='text'
                              value={hints[`${word.number}-${word.direction}`] || ''}
                              className='mobile-clue-input'
                              id={`hint-input-${word.number}-${word.direction}`}
                              placeholder={`clue for ${word.answer}`}
                              onChange={(e) => handleClueChange(e, word.number, word.direction)}
                              onFocus={() => {
                                setActiveInput('clue');
                                setSelectedClue({ wordNumber: word.number, wordDirection: 1 });
                              }}
                              readOnly={true}
                            />
                            {errors.hints[`${word.number}-${word.direction}`] && (
                              <div className="error-icon">
                                <i className='bx bxs-error-circle'></i>
                                <div className="mobile-error-message">{errors.hints[`${word.number}-${word.direction}`]}</div>
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* Title Section */}
                <div className='mobile-create-title-container'>
                  <div className='mobile-create-subheading-title'>title</div>
                  <div className='mobile-title-input-container'>
                    <input
                      type='text'
                      value={title || ''}
                      className='mobile-title-input'
                      placeholder='title'
                      onChange={(e) => handleTitleChange(e)}
                      onFocus={() => setActiveInput('title')}
                      readOnly={true}
                    />
                    {errors.title && (
                      <div className="title-error-icon">
                        <i className='bx bxs-error-circle'></i>
                        <div className="mobile-error-message">{errors.title}</div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Submit Button */}
                <button className='mobile-create-submit-cw-button' onClick={submitCrossword}>
                  submit crossword
                </button>
              </div>
            )}


            {!showWords && displayInstruction && (
              <div className='mobile-create-instruction-container'>
                <div className='mobile-create-instruction-header'> 
                  you can fill out information after, for now, just make the grid
                  <div className='mobile-create-instruction-subheading'> 
                    use '-' for an explicit black square
                  </div>
                </div>      
              </div>
            )}

            {!showWords && !displayInstruction && (
              <div className='mobile-word-values-container'>

                {completeWords.length > 0 && (
                  <div className="mobile-word-grid-container">
                    {completeWords.map((word, index) => (
                      <div key={index} className="mobile-word-cell" onClick={() => handleCompleteWordClick(word)}>
                        {word}
                      </div>
                    ))}
                  </div>
                )}

                {completeWords.length === 0 && !loadingCompleteWords && (
                  <div className='mobile-warning-container'>
                    <div className='mobile-no-word-warn'>
                      <p>no words</p>
                    </div>
                  </div>
                )}

                {loadingCompleteWords && (
                  <div className='mobile-loading-words'>loading...</div>
                )}

              </div>
            )}  

            <CustomKeyboard
              onKeyPress={(key) => handleKeyDown({ key }, selectedCell.y, selectedCell.x)}
              hasEnter={false}
              isCreate={true}
              isEditing={isEditing} 
            />

        </div>
      ) : (
        /* desktop */
        <div className='create-container'>

          {showSuccessPopup && <SuccessPopup onClose={() => setShowSuccessPopup(false)} />}

          <div className="create-grid-container">

            <div className='create-back-header'>
              <button className='create-back-button' onClick={() => navigate('/')}>
                <i className='bx bxs-left-arrow'></i>
              </button>  
              <div className='create-grid-header'>create your crossword</div>
              <button className='create-reset-button' onClick={() => resetBoard()}>
                <i className='bx bx-reset'></i>
              </button>  
            </div>

            <CrosswordGrid
              grid={grid}
              selectedWord={selectedWord}
              selectedCell={selectedCell}
              handleKeyDown={handleKeyDown}
              numberedCells={numberedCells}
              onCellUpdate={handleCellUpdate}
              onCellFocus={handleCellFocus}
              onNextCell={onNextCell}
              onPrevCell={onPrevCell}
              blackEditable={true}
              canFocus={isEditing}
              enforceBlack={!isEditing}
              allowDashes={true}
            />

            <div className='create-button-container'>
              {isEditing ? (
                <button className='create-submit-button' onClick={handleSubmitWords}>submit</button>
              ) : (
                <button className='create-edit-button' onClick={handleEdit}>edit</button>
              )}
            </div>
          </div>

          {showWords && (
            <div className='word-hint-outer-container'> 
              <div className='word-hint-container'> 

                <div className='create-words'>
                  <div>
                    <div>
                      <div className='across-words-container'> 
                        <div className='create-subheading'> across </div>
                        <ul className='across-words'>
                          {acrossWords.map((word) => (
                            <li key={`${word.number}-${word.direction}`}>
                              <span className='word-number'>{word.number}:</span> <span className='word-text'>{word.answer}</span>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className='across-words-container'> 
                        <div className='create-subheading'> down </div>
                        <ul className='down-words'>
                          {downWords.map((word) => (
                            <li key={`${word.number}-${word.direction}`}>
                              <span className='word-number'>{word.number}:</span> <span className='word-text'>{word.answer}</span>
                            </li>
                          ))}
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>

                <div className='create-clues'>
                  <div className='create-subheading-clues'>clues</div>

                  <ul className='across-clues'>
                    {acrossWords.map((word) => (
                      <li key={`${word.number}-${word.direction}`}>
                        <div className='hint-input-container'>
                          <input
                            type='text'
                            value={hints[`${word.number}-${word.direction}`] || ''}
                            className='clue-input'
                            placeholder={`clue for ${word.answer}`}
                            onChange={(e) => handleClueChange(e, word.number, word.direction)}
                          />
                          {errors.hints[`${word.number}-${word.direction}`] && (
                            <div className="error-icon">
                              <i className='bx bxs-error-circle'></i>
                              <div className="error-message">{errors.hints[`${word.number}-${word.direction}`]}</div>
                            </div>
                          )}  
                        </div>
                      </li>
                    ))}
                  </ul>

                  <ul className='down-clues'>
                    {downWords.map((word) => (
                      <li key={`${word.number}-${word.direction}`}>
                        <div className='hint-input-container'>
                          <input
                            type='text'
                            value={hints[`${word.number}-${word.direction}`] || ''}
                            className='clue-input'
                            placeholder={`clue for ${word.answer}`}
                            onChange={(e) => handleClueChange(e, word.number, word.direction)}
                          />
                          {errors.hints[`${word.number}-${word.direction}`] && (
                            <div className="error-icon">
                              <i className='bx bxs-error-circle'></i>
                              <div className="error-message">{errors.hints[`${word.number}-${word.direction}`]}</div>
                            </div>
                          )} 
                        </div>
                      </li>
                    ))}
                  </ul>

                </div>
              </div>

              <div className='create-title-container'> 
                <div className='create-subheading-title'> title </div>

                <div className='title-input-container'>
                  <input
                    type='text'
                    value={title || ''}
                    className='title-input'
                    placeholder={`title`}
                    onChange={(e) => handleTitleChange(e)}
                  />
                  {errors.title && (
                    <div className="title-error-icon">
                      <i className='bx bxs-error-circle'></i>
                      <div className="error-message">{errors.title}</div>
                    </div>
                  )}
                </div>
              </div>

              <button className='create-submit-cw-button' onClick={submitCrossword}>submit crossword</button>
            </div>
          )}

          {!showWords && displayInstruction && (
            <div className='create-instruction-container'>
              <div className='create-instruction-header'> 
                you can fill out information after, for now, just make the grid
                <div className='create-instruction-subheading'> 
                  use '-' for an explicit black square
                </div>
              </div>      
            </div>
          )}

          {/* Grid container for displaying wordValues */}
          {!showWords && !displayInstruction && (
            <div className='word-values-container'>
              <div className="word-values-grid">
                {wordValues.map((value, index) => (
                  <div key={index} className="display-word-cell">{value || ' '}</div>
                ))}
              </div> 

              <div className="word-grid-container">
                {completeWords.map((word, index) => (
                  <div key={index} className="word-cell" onClick={() => handleCompleteWordClick(word)}>
                    {word}
                  </div>
                ))}
              </div>

              {completeWords.length === 0 && (
                <div className='no-word-warn'>
                  <p>no words</p>
                </div>
              )}

              {loadingCompleteWords && (
                <div className='loading-words'>loading...</div>
              )}
            </div>
          )}  
          
        </div>
      )}

      {showErrorMessage && (
        <Alert message={errorMessage} isMobile={isMobile} />
      )}
    </div>
  );

}

export default Create;
