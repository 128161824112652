/* lib imports */
import React, { useContext, useState, useEffect } from 'react';
import AuthContext from '../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* components */
import VisualCrosswordGrid from '../components/VisualCrosswordGrid.js'; 

/* style */
import '../style/profile.css';
import colorSets from '../config/colorSets.js'; 

/* profile page */
function Profile() {

  /* create history object for navigation */
  const navigate = useNavigate(); 

  /* get the user (logged in) */
  const { user, updateUserColorPreference, updateUser } = useContext(AuthContext);

  /* state for profile when viewing other users */
  const [profile, setProfile] = useState(null);

  /* get other user if provided and distinguish appropriately */
  const { username: queriedUsername } = useParams();
  const isOwnProfile = !queriedUsername || queriedUsername === user?.username;

  /* consolidate details to render */
  const displayProfile = isOwnProfile ? user : profile;

  /* state for puzzles */
  const [puzzles, setPuzzles] = useState([]);
  const [loadingPuzzles, setLoadingPuzzles] = useState(true);

  /* profile color select */
  const [selectedColor, setSelectedColor] = useState('');
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const colorNames = Object.keys(colorSets);
  const currentColorName = colorNames[currentColorIndex];
  const currentColor = colorSets[currentColorName];

  /* friend searching */
  const [friendSearchQuery, setFriendSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]); 
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [showRequestsPopoup, setShowRequestsPopup] = useState(false);

  /* update the page style & selected color based on the displayed profile’s colorPreference */
  useEffect(() => {
    const currentProfile = isOwnProfile ? user : profile;
    if (isOwnProfile && currentProfile && currentProfile.colorPreference) {
      setSelectedColor(currentProfile.colorPreference);
      const initialIndex = colorNames.indexOf(currentProfile.colorPreference);
      if (initialIndex !== -1) {
        setCurrentColorIndex(initialIndex);
      }
      const chosenPalette = colorSets[currentProfile.colorPreference];
      if (chosenPalette) {
        document.documentElement.style.setProperty('--color-dark', chosenPalette.dark);
        document.documentElement.style.setProperty('--color-normal', chosenPalette.normal);
        document.documentElement.style.setProperty('--color-light', chosenPalette.light);
      }
    }
  }, [user, profile, isOwnProfile, colorNames]);

  /* update user to see async changes */
  const refreshUser = async () => {
    try {
      if (isOwnProfile) {
        // For own profile, re-fetch and update context.
        const response = await fetch(`${apiUrl}/auth/profile`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        const data = await response.json();
        if (data) {
          updateUser(data);
        }
      } else {
        // For other profiles, update local state.
        const response = await fetch(`${apiUrl}/user/profile/${queriedUsername}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        const data = await response.json();
        setProfile(data);
      }
    } catch (error) {
      console.error("Error refreshing user data:", error);
    }
  };

  /* retrieve other profile's information if provided */
  useEffect(() => {
    if (!isOwnProfile) {
      const fetchUserProfile = async () => {
        try {
          const response = await fetch(`${apiUrl}/user/profile/${queriedUsername}`);
          if (!response.ok) throw new Error('User not found');
          const data = await response.json();
          setProfile(data);
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      };
      fetchUserProfile();
    }
  }, [queriedUsername, isOwnProfile]);

  /* mobile handling */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* search users */
  const handleSearch = async () => {
    if (!friendSearchQuery.trim()) {
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/user/search?username=${friendSearchQuery}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      if (!response.ok) {
        throw new Error("Error searching for user");
      }
      const data = await response.json();
      setSearchResults(data);
      if (data.length > 0) {
        setShowSearchPopup(true);
      }
    } catch (error) {
      console.error("Search error:", error);
    }
  };
  

  /* send request */
  const handleSendFriendRequest = async (friendUsername) => {
    try {
      const response = await fetch(`${apiUrl}/user/friends/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ friendUsername })
      });
      if (response.ok) {
        refreshUser();
      } else {
        const data = await response.json();
        alert(data.message || 'Error sending friend request');
      }
    } catch (error) {
      console.error('Error sending friend request:', error);
    }
  };

  /* accept request */
  const handleAcceptFriend = async (requesterId) => {
    try {
      const response = await fetch(`${apiUrl}/user/friends/accept`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ requesterId })
      });
      if (response.ok) {
        refreshUser()
      } else {
        const data = await response.json();
        alert(data.message || 'Error accepting friend request');
      }
    } catch (error) {
      console.error('Error accepting friend request:', error);
    }
  };

  /* reject request */
  const handleRejectFriend = async (requesterId) => {
    try {
      const response = await fetch(`${apiUrl}/user/friends/reject`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ requesterId })
      });
      if (response.ok) {
        refreshUser()
      } else {
        const data = await response.json();
        alert(data.message || 'Error rejecting friend request');
      }
    } catch (error) {
      console.error('Error rejecting friend request:', error);
    }
  };

  /* handle new color selection (only allowed for own profile) */
  const handleColorSelect = async (colorName, colors) => {
    if (!isOwnProfile) return; // prevent changes on others' profiles

    /* update local */
    setSelectedColor(colorName);

    /* hit our endpoint and update the user */
    try {
      const response = await fetch(`${apiUrl}/user/update-color-set`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          colorSet: { name: colorName }
        })
      });
      const data = await response.json();
      if (response.ok) {
        updateUserColorPreference(colorName);
      } else {
        throw new Error(data.message || 'Failed to update color set');
      }
    } catch (error) {
      console.error('Error updating color set:', error);
    }
  };

  // Handle left arrow click
  const handlePreviousColor = () => {
    setCurrentColorIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? colorNames.length - 1 : prevIndex - 1;
      // Automatically select the new color
      handleColorSelect(colorNames[newIndex], colorSets[colorNames[newIndex]]);
      return newIndex;
    });
  };

  // Handle right arrow click
  const handleNextColor = () => {
    setCurrentColorIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % colorNames.length;
      // Automatically select the new color
      handleColorSelect(colorNames[newIndex], colorSets[colorNames[newIndex]]);
      return newIndex;
    });
  };

  // Update the selected color (when clicking on the color box)
  const handleColorChange = () => {
    handleColorSelect(currentColorName, currentColor);
  };

  /* fetch puzzles based on the displayed profile’s puzzlesCreated */
  useEffect(() => {
    const fetchPuzzles = async () => {
      const puzzlesCreated = isOwnProfile ? user?.puzzlesCreated : displayProfile?.puzzlesCreated;
      if (puzzlesCreated && puzzlesCreated.length > 0) {
        try {
          const response = await fetch(`${rootUrl}/puzzles/details`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({ puzzleIds: puzzlesCreated }),
          });
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }
          const puzzleDetails = await response.json();
          setPuzzles(puzzleDetails);
        } catch (error) {
          console.error('Error fetching puzzles:', error);
        } finally {
          setLoadingPuzzles(false);
        }
      } else {
        setLoadingPuzzles(false);
      }
    };
    // Only fetch puzzles if viewing own profile or if the other profile has been loaded
    if (isOwnProfile || displayProfile) {
      fetchPuzzles();
    }
  }, [user, displayProfile, isOwnProfile]);

  /* visual */
  const backHeaderClass = isOwnProfile ? 'back-profile-header' : 'back-profile-header-notme';
  if (isMobile) {
    return (
      <div className='mobile-profile-container'>
        <div className='mobile-profile-header'>
          <button className='mobile-profile-back-button' onClick={() => navigate(-1)}>
            <i className='bx bxs-left-arrow'></i>
          </button>           
          <h1>{displayProfile?.username || '...'}</h1>
          <button className='mobile-profile-button' onClick={() => navigate('/play/random')}>
            <i className='bx bxs-dice-6'></i>
          </button>
        </div>

        <div className='mobile-full-info-container'> 
          <div className='mobile-traits-and-color'>
            <div className="mobile-profile-details">
              <div className="profile-item">
                <strong>skill rating</strong> <span>{displayProfile?.skillRating}</span>
              </div>
              <div className="profile-item">
                <strong>puzzles</strong> <span>{displayProfile?.puzzlesCreated?.length}</span>
              </div>
              <div className="profile-item">
                <strong>friends</strong> <span>{displayProfile?.friends?.length}</span>
              </div>
            </div>

            {/* Render color slider only for own profile */}
            {isOwnProfile ? (
              <div className="color-slider">
                <button className="arrow-button" onClick={handlePreviousColor}>
                  <i className='bx bxs-left-arrow'></i>
                </button>

                <div
                  className="current-color-box"
                  style={{
                    backgroundColor: currentColor.light,
                    width: '100px',
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    border: '3px solid black',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                  }}
                  onClick={handleColorChange}
                  onMouseEnter={e => e.currentTarget.style.backgroundColor = currentColor.normal}
                  onMouseLeave={e => e.currentTarget.style.backgroundColor = currentColor.light}
                />
                
                <button className="arrow-button" onClick={handleNextColor}>
                  <i className='bx bxs-right-arrow'></i>
                </button>
              </div>
            ) : (
                <div
                  className="single-color-box"
                  style={{
                    backgroundColor: (profile && profile.colorPreference && colorSets[profile.colorPreference]
                      ? colorSets[profile.colorPreference].light
                      : colorSets[Object.keys(colorSets)[0]].light),
                    width: '100px',
                    height: '100px',
                    border: '3px solid black',
                    transition: 'background-color 0.3s',
                    marginTop: '43px',
                    marginRight: '55px',
                  }}
                >
                </div>
            )}
          </div>

          {displayProfile ? (
            <>
              <div className="friends-section">
                {isOwnProfile ? (
                  <>
                    {/* List incoming friend requests */}
                    <div className="requests-mobile">
                      <div className="friend-search">
                        <input
                          className="mobile-input-friend"
                          type="text"
                          value={friendSearchQuery}
                          onChange={(e) => setFriendSearchQuery(e.target.value)}
                          placeholder="search by username"
                        />
                        <div className='friends-horiz'>
                          <button className="search-friend-button" onClick={handleSearch}>search</button>
                          <button className="req-button" onClick={() => setShowRequestsPopup(true)}>requests</button>
                        </div>
                      </div>

                    </div>
                  </>
                ) : (
                  <div className="friend-action">
                    {user.friends && user.friends.includes(displayProfile._id) ? (
                      <p className='pro-txt'>you are friends</p>
                    ) : displayProfile.friendRequestsReceived.includes(user._id) ? (
                      <p className='pro-txt'>request sent</p>
                    ) : (
                      <button className='search-friend-button' onClick={() => handleSendFriendRequest(displayProfile.username)}>add friend</button>
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="loading-container" style={{ flex: 1 }}>
              <div className="loading-message">
                loading crossword...
              </div>
            </div>
          )}
        </div>

        <div className='mobile-puzzle-details'>
          <div className='mobile-trait-header'>{isOwnProfile ? 'puzzles' : 'puzzles'}</div>
          <div className="mobile-puzzles-list">
            {loadingPuzzles ? (
              <p>loading puzzles...</p>
            ) : (
              puzzles.length > 0 ? (
                <ul>
                  {puzzles.map(puzzle => (
                    <li key={puzzle._id}>
                      <strong>{puzzle.title}</strong>
                      <VisualCrosswordGrid grid={puzzle.grid} />
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="mobile-no-puzzle-header">no puzzles available!</div>
              )
            )}
          </div>
        </div>

        {showSearchPopup && (
          <div className="overlay">
            <div className="mobile-profile-popup">
              <div className="popup-content">

                <div className='popup-header'>
                  <button className="close-results-button" onClick={() => setShowSearchPopup(false)}>
                    <i className='bx bxs-left-arrow'></i>
                  </button>
                  <h3>results</h3>
                </div>

                {searchResults.length > 0 ? (
                  <ul className="mobile-result-list">
                    {searchResults.map(result => {
                      let iconClass = "bx bx-plus"; 
                    
                      if (user.friends && user.friends.includes(result._id)) {
                        iconClass = "bx bx-check"; // already friends
                      } else if (user.friendRequestsSent && user.friendRequestsSent.includes(result._id)) {
                        iconClass = "bx bx-time-five"; // request pending
                      } else if (user.friendRequestsReceived && user.friendRequestsReceived.includes(result._id)) {
                        iconClass = "bx bx-warning"; // incoming request
                      }

                      return (
                        <li className="result-item" key={result._id}>
                          {result.username}
                          {iconClass === "bx bx-plus" ? (
                            <button
                              className="add-friend-button"
                              onClick={() => handleSendFriendRequest(result.username)}
                            >
                              <i className={iconClass}></i>
                            </button>
                          ) : (
                            <span className="other-friend-button">
                              <i className={iconClass}></i>
                            </span>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <div className="no-users">no users found</div>
                )}

              </div>
            </div>
          </div>
        )}

        {showRequestsPopoup && (
          <div className="overlay">
            <div className="mobile-profile-popup">
              <div className="popup-content">
                <div className="popup-header">
                  <button className="close-results-button" onClick={() => setShowRequestsPopup(false)}>
                    <i className="bx bxs-left-arrow"></i>
                  </button>
                  <h3>requests</h3>
                </div>

                {user.friendRequestsReceived && user.friendRequestsReceived.length > 0 ? (
                  <ul className="mobile-result-list">
                    {user.friendRequestsReceived.map(request => (
                      <li className="result-item" key={request._id}>
                        <span className="request-username">{request.username}</span>
                        <div className="request-buttons">
                          <button
                            className="accept-friend-button"
                            onClick={() => handleAcceptFriend(request._id)}
                          >
                            <i class='bx bx-check' ></i>
                          </button>
                          <button
                            className="add-friend-button"
                            onClick={() => handleRejectFriend(request._id)}
                          >
                            <i class='bx bx-x'></i>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="no-users">no friend requests</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="profile-container">
        {/* Desktop layout */}
        <div className={backHeaderClass}>
          <button className='profile-back-button' onClick={() => navigate(-1)}>
            <i className='bx bxs-left-arrow'></i>
          </button>
          <h1>{displayProfile?.username || '...'}</h1>
          {/* Render color container only if viewing your own profile */}
          {isOwnProfile ? (
            <div className="color-container">
              {Object.entries(colorSets).map(([setName, colors]) => (
                <div
                  key={setName}
                  className={`color-set-cell`}
                  style={{
                    backgroundColor: colors.light,
                    padding: '10px',
                    margin: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                    border: selectedColor === setName ? '3px solid black' : 'none',
                  }}
                  onMouseEnter={e => e.currentTarget.style.backgroundColor = colors.normal}
                  onMouseLeave={e => e.currentTarget.style.backgroundColor = colors.light}
                  onClick={() => handleColorSelect(setName, colors)}
                />
              ))}
            </div>
          ) : (
            <div
              className="current-color-box"
              style={{
                backgroundColor: (profile && profile.colorPreference && colorSets[profile.colorPreference]
                  ? colorSets[profile.colorPreference].light
                  : colorSets[Object.keys(colorSets)[0]].light),                
                  width: '80px',
                height: '80px',
                display: 'flex',
                marginLeft: '65px',
                marginTop: '20px',
                flexShrink: 0,
                border: '3px solid black',
                transition: 'background-color 0.3s',
              }}
            >
            </div>
          )}

        </div>

        {displayProfile ? (
          <>
            <div className="profile-details">
              <div className="profile-item">
                <strong>skill rating</strong> <span>{displayProfile?.skillRating}</span>
              </div>
              <div className="profile-item">
                <strong>puzzles</strong> <span>{displayProfile?.puzzlesCreated?.length}</span>
              </div>
            </div>

            <div className="friends-section">
              {isOwnProfile ? (
                <>
                  {/* List incoming friend requests */}
                  <div className="requests">
                    <div className='friends-vert'>
                      <div className="profile-item">
                        <strong>friends</strong> <span>{user.friends.length}</span>
                      </div>
                      <div className="profile-item">
                        <button className="req-button" onClick={() => setShowRequestsPopup(true)}>requests</button>
                      </div>
                    </div>
                    <div className="friend-search">
                      <input
                        className="input-friend"
                        type="text"
                        value={friendSearchQuery}
                        onChange={(e) => setFriendSearchQuery(e.target.value)}
                        placeholder="search by username"
                      />
                      <button className="search-friend-button" onClick={handleSearch}>search</button>
                    </div>

                  </div>
                </>
              ) : (
                <div className="friend-action">
                  {user.friends && user.friends.includes(displayProfile._id) ? (
                    <p className='pro-txt'>you are friends</p>
                  ) : displayProfile.friendRequestsReceived.includes(user._id) ? (
                    <p className='pro-txt'>request sent</p>
                  ) : (
                    <button className='search-friend-button' onClick={() => handleSendFriendRequest(displayProfile.username)}>add friend</button>
                  )}
                </div>
              )}
            </div>
        

            <div className='puzzle-details'>
              <div className='trait-header'>{isOwnProfile ? 'your puzzles' : 'puzzles'}</div>
              <div className="puzzles-list">
                {loadingPuzzles ? (
                  <p>loading puzzles...</p>
                ) : (
                  puzzles.length > 0 ? (
                    <ul>
                      {puzzles.map(puzzle => (
                        <li key={puzzle._id}>
                          <strong>{puzzle.title}</strong>
                          <VisualCrosswordGrid grid={puzzle.grid} />
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="no-puzzle-header">no puzzles available!</div>
                  )
                )}
              </div>
            </div>
            {showSearchPopup && (
                      <div className="overlay">
                        <div className="profile-popup">
                          <div className="popup-content">

                            <div className='popup-header'>
                              <button className="close-results-button" onClick={() => setShowSearchPopup(false)}>
                                <i className='bx bxs-left-arrow'></i>
                              </button>
                              <h3>results</h3>
                            </div>

                            {searchResults.length > 0 ? (
                              <ul className="result-list">
                                {searchResults.map(result => {
                                  let iconClass = "bx bx-plus"; 
                                
                                  if (user.friends && user.friends.includes(result._id)) {
                                    iconClass = "bx bx-check"; // already friends
                                  } else if (user.friendRequestsSent && user.friendRequestsSent.includes(result._id)) {
                                    iconClass = "bx bx-time-five"; // request pending
                                  } else if (user.friendRequestsReceived && user.friendRequestsReceived.includes(result._id)) {
                                    iconClass = "bx bx-warning"; // incoming request
                                  }

                                  return (
                                    <li className="result-item" key={result._id}>
                                      {result.username}
                                      {iconClass === "bx bx-plus" ? (
                                        <button
                                          className="add-friend-button"
                                          onClick={() => handleSendFriendRequest(result.username)}
                                        >
                                          <i className={iconClass}></i>
                                        </button>
                                      ) : (
                                        <span className="add-friend-button disabled">
                                          <i className={iconClass}></i>
                                        </span>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <div className="no-users">no users found</div>
                            )}

                          </div>
                        </div>
                      </div>
                    )}

                    {showRequestsPopoup && (
                      <div className="overlay">
                        <div className="profile-popup">
                          <div className="popup-content">
                            <div className="popup-header">
                              <button className="close-results-button" onClick={() => setShowRequestsPopup(false)}>
                                <i className="bx bxs-left-arrow"></i>
                              </button>
                              <h3>requests</h3>
                            </div>

                            {user.friendRequestsReceived && user.friendRequestsReceived.length > 0 ? (
                              <ul className="result-list">
                                {user.friendRequestsReceived.map(request => (
                                  <li className="result-item" key={request._id}>
                                    <span className="request-username">{request.username}</span>
                                    <div className="request-buttons">
                                      <button
                                        className="accept-friend-button"
                                        onClick={() => handleAcceptFriend(request._id)}
                                      >
                                        <i class='bx bx-check' ></i>
                                      </button>
                                      <button
                                        className="add-friend-button"
                                        onClick={() => handleRejectFriend(request._id)}
                                      >
                                        <i class='bx bx-x'></i>
                                      </button>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <div className="no-users">no friend requests</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
          </>
        ) : (
          <div className="loading-container" style={{ flex: 1 }}>
            <div className="loading-message">
              loading crossword...
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Profile;
