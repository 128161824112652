/* lib imports */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

/* context */
import AuthContext from '../context/AuthContext';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* components */
import Comments from '../components/Comments';

/* helpers */
import { formatTime } from '../pages/Play'

/* style */
import '../style/dashboard.css';

/* dashboard */
function Dashboard() {
    /* state variables */
    const [puzzles, setPuzzles] = useState([]);
    const [selectedPuzzle, setSelectedPuzzle] = useState(null);

    /* mobile handling */
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    /* no vertical scrolling on mobile */
    useEffect(() => {
        if (isMobile) {
          document.body.style.overflow = 'hidden';
        }
        return () => {
          document.body.style.overflow = 'auto';
        };
    }, [isMobile]);

    /* navigate init */
    const navigate = useNavigate();

    /* get the user */
    const { user } = useContext(AuthContext);

    /* on mount, fetch puzzles and user entries */
    useEffect(() => {
        async function fetchPuzzlesAndEntries() {
            try {
                const puzzlesResponse = await fetch(`${apiUrl}/puzzles/list`);
                if (!puzzlesResponse.ok) {
                    throw new Error(`HTTP error! Status: ${puzzlesResponse.status}`);
                }
                let puzzlesData = await puzzlesResponse.json();
                
                const userId = user._id;
                const entriesResponse = await fetch(`${apiUrl}/leaderboard/user-entries/${userId}`,   
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                });
                if (!entriesResponse.ok) {
                    throw new Error(`HTTP error! Status: ${entriesResponse.status}`);
                }
                const entriesData = await entriesResponse.json();

                const playedPuzzleIds = new Set(entriesData.map(entry => entry.puzzleId));
                let markedPuzzles = puzzlesData.map(puzzle => ({
                    ...puzzle,
                    played: playedPuzzleIds.has(puzzle._id)
                }));

                markedPuzzles.sort((a, b) => {
                    if (a.played !== b.played) {
                        return a.played ? 1 : -1;
                    }
                    return (b.averageRating || 0) - (a.averageRating || 0);
                });

                setPuzzles(markedPuzzles);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }

        fetchPuzzlesAndEntries();
    }, []); 

    /* visual */
    return (
        <div className="dashboard-container">
            {isMobile ? (
               <div className="mobile-only-novscroll"> 
                    <div className="mobile-play-header"> 
                        <button className='mobile-dashboard-back-button' onClick={() => navigate('/')}>
                            <i className='bx bxs-left-arrow'></i>
                        </button>           
                        <h1>play</h1>
                        <button className='mobile-dashboard-random-button' onClick={() => navigate('/play/random')}>
                            <i className='bx bxs-dice-6'></i>
                        </button>
                    </div>

                    <div className="mobile-ultimate-content-container">
                        <div className='mobile-puzzle-list-header'>
                            <div className='mobile-name-header'>puzzle</div>
                            <div className='mobile-rating'>rating</div>
                        </div>

                        <div className="mobile-puzzle-grid">
                        {puzzles.map(puzzle => (
                            <div key={puzzle._id} className="puzzle-container">
                                {selectedPuzzle && selectedPuzzle._id === puzzle._id ? (
                                    <div className="mobile-play-container"> 
                                        <div className="mobile-puzzle-title">
                                            {puzzle.title}
                                        </div>
                                        <button className='mobile-dash-play-button' onClick={() => navigate(`/play/${selectedPuzzle._id}`)}>PLAY</button>
                                    </div>
                                ) : (
                                    <div className={`mobile-puzzle-item`} onClick={() => setSelectedPuzzle(puzzle)}>
                                        <div className="puzzle-title">
                                            {puzzle.title}
                                            {puzzle.played && 
                                                <span className="played-icon">
                                                    <i className='bx bxs-check-circle' title="Played"></i>
                                                </span>
                                            }
                                        </div>                                    
                                        <div className='mobile-puzzle-rating'>{!puzzle.averageRating || puzzle.averageRating === 0 ? '-' : puzzle.averageRating.toFixed(1)}</div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    </div>

               </div>
            ) : (
                <div className="desktop-only">
                    <div className='ultimate-header-container'>
                        <div className='dashboard-header-container'> 
                            <button className='dashboard-back-button' onClick={() => navigate('/')}>
                                <i className='bx bxs-left-arrow'></i>
                            </button>           
                            <h1>play</h1>
                            <button className='dashboard-random-button' onClick={() => navigate('/play/random')}>
                                <i className='bx bxs-dice-6'></i>
                            </button>
                        </div>
                    </div>
                    <div className='ultimate-content-container'>
                        <div className='dashboard-puzzles-subcontain'>
                            <div className='puzzle-list-header'>
                                <div className='name-header'>puzzle name</div>
                                <div className='play-or-rating'>plays</div>
                                <div className='play-or-rating'>rating</div>
                            </div>
                            <div className="puzzle-grid">
                                {puzzles.map(puzzle => (
                                    <div key={puzzle._id} className="puzzle-container">
                                        <div className={`puzzle-item ${selectedPuzzle && selectedPuzzle._id === puzzle._id ? 'selected-puzzle' : ''}`} onClick={() => setSelectedPuzzle(puzzle)}>
                                            <div className="puzzle-title">
                                                {puzzle.title}
                                                {puzzle.played && 
                                                    <span className="played-icon">
                                                        <i className='bx bxs-check-circle' title="Played"></i>
                                                    </span>
                                                }
                                            </div>                                    
                                            <div className="puzzle-plays">{!puzzle.numberOfPlays || puzzle.numberOfPlays === 0 ? '-' : puzzle.numberOfPlays}</div>
                                            <div className='puzzle-rating'>{!puzzle.averageRating || puzzle.averageRating === 0 ? '-' : puzzle.averageRating.toFixed(1)}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='puzzle-details-container'>
                            {selectedPuzzle ? (
                                <div>
                                    <h2>{selectedPuzzle.title}</h2>
                                    <div className='horizontal-item-container'>
                                        <div className="dashboard-item">
                                            <div className='plays'>plays</div>
                                            <p>{selectedPuzzle.numberOfPlays || '-'}</p>
                                        </div>
                                        <div className="dashboard-item">
                                            <strong>average completion time</strong>
                                            <p>{selectedPuzzle.averageCompletionTime ? formatTime(selectedPuzzle.averageCompletionTime) : '-'}</p>
                                        </div>
                                    </div>
                                    <div className="dashboard-item">
                                        <strong>rating</strong>
                                        <p>{selectedPuzzle.averageRating ? selectedPuzzle.averageRating.toFixed(1) : '-'}</p>
                                    </div>
                                    <Comments puzzleId={selectedPuzzle._id} user={user}/>
                                    <button className='dash-play-button' onClick={() => navigate(`/play/${selectedPuzzle._id}`)}>PLAY</button>
                                </div>
                            ) : (
                                <div className='dash-instruction-header'>select a puzzle to view details</div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Dashboard;
