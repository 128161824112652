/* lib imports */
import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

/* auth context  */
import AuthContext from '../context/AuthContext';

/* style */
import '../style/auth.css';

/* login page */
const Login = () => {

  /* state variables */
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, token, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  /* on submission */
  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(username, password);
  };

  /* mobile handling */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
  }, []);

  /* if we are not loading with valid token, take us home. */
  useEffect(() => {
    if (!loading && token) {
      navigate('/')
    }
  }, [loading, token]);

  /* visual */
  return (
    <div className='auth-container'>
      {isMobile ? (
        /* Mobile Version */
        <div className='auth-container'>  
          <div className='mobile-auth-header'> Crossword Fight </div>
          <form onSubmit={handleSubmit}>
            <div className='register-input-container'>
              <input 
                className='mobile-username-input'
                type="text"
                placeholder="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                className='mobile-password-input'
                type="password"
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button 
              type="submit"
              className='mobile-submit-button'>
                login
            </button>
          </form>
        <div className='mobile-register-link'>
          <Link to="/register"> register </Link>
        </div>
        <button className="google-btn" onClick={() => window.location.href='https://api.crosswordfight.com/api/auth/google'}>
            <span>sign in with Google</span>
          </button>
    </div>
      ) : (
        /* Desktop Version */
        <div className='auth-container'>  
          <div className='auth-header'> Crossword Fight </div>
          <form onSubmit={handleSubmit}>
            <div className='register-input-container'>
              <input 
                className='username-input'
                type="text"
                placeholder="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                className='password-input'
                type="password"
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button 
              type="submit"
              className='submit-button'>
                login
            </button>
          </form>
          <div className='register-link'>
            <Link to="/register"> register </Link>
          </div>
          
          <button className="google-btn" onClick={() => window.location.href='https://api.crosswordfight.com/api/auth/google'}>
            <span>sign in with Google</span>
          </button>
      </div>
      )}
    </div>
  );
};

export default Login;
